import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/394962342"
    notesSrc="https://www.docdroid.net/D7jNNpt/bible-group-homework-2-293-1.pdf"
    notesText="Bible Group Homework"
  >
    <SEO title="The King is Coming - Love Song" />
  </Layout>
)

export default SermonPost
